import React, { createContext, useEffect, useContext } from "react";
import { version } from "../../package.json";

import { useState, useRef } from "react";
import getWeb3Instance, {
  callBatchMethod,
  callContractMethod,
  onlyCallBatchMethod,
  onlyCallContractMethod,
  web3Instance,
} from "../web3";
import * as util from "../util";
import {
  constants,
  ENV_VOTING_PROPOSAL_LIST,
  EXITED_NCP_LIST,
} from "../constants";
import { ModalContext } from "../contexts/ModalContext";
import Web3 from "web3";
import * as abis from "../abis/index";
import { getPlatformList } from "../core/coreApi.js";
import BigNumber from "bignumber.js";
import { LandingDataStateContext } from "./LandingDataContext.jsx";

const initData = {
  ballotTypeData: [],
  stakingMax: null,
  stakingMin: null,
  votingDurationMin: null,
  votingDurationMax: null,
  memberLength: 0,
  voteLength: 0,
  authorityOriginData: [],
  authorityNames: new Map(),
  ballotMemberOriginData: {},
  ballotBasicOriginData: {},
  waitBallotLength: 0,
  waitBallotMemberOriginData: {},
  waitBallotBasicOriginData: {},
  firstBlockTimestamp: 0,
  isWithdrawal: {},
  proposalMemberLength: 0,
};

const GovInitCtx = createContext();

const GovInitProvider = ({ children }) => {
  const { getErrModal } = useContext(ModalContext);
  const { current: data } = useRef(initData);

  // const [web3Ins, setWeb3Ins] = useState();
  const [isContractReady, setIsContractReady] = useState(false);
  const [isWeb3Loaded, setIsWeb3Loaded] = useState(false);
  const [accessFailMsg, setAccessFailMsg] = useState("");

  const { isLandingDataReady, isLandingDataError, wondersList } = useContext(
    LandingDataStateContext,
  );

  useEffect(() => {
    if (isLandingDataReady || isLandingDataError) {
      init();
    }
  }, [isLandingDataReady, isLandingDataError]);

  const init = async () => {
    await getWeb3Instance().then(
      async (data) => {
        // get governance variables
        await getGovernanceVariables();
        // get authority list and ballot data
        await getContractAuthorityBallots();
        // 투표 정보를 가져옴
        // await getVoteInfo();
        // get WAIT Protocol ballot data
        await getWaitBallots();
        // platform menu
        await getPlatform();

        console.log("debugMode: ", constants.debugMode);
        setIsWeb3Loaded(true);
      },
      async (error) => {
        console.log("getWeb3 error: ", error);
        setIsWeb3Loaded(false);
        setAccessFailMsg(error.message);
      },
    );
  };

  const getGovernanceVariables = async () => {
    const br = new web3Instance.web3.BatchRequest();
    // related staking (get the minimum and maximum values that can be staked)
    br.add(onlyCallBatchMethod(web3Instance, "EnvStorageImp", "getStakingMin"));
    br.add(onlyCallBatchMethod(web3Instance, "EnvStorageImp", "getStakingMax"));
    // related voting duration (get voting duration minium and maximum values)
    br.add(
      onlyCallBatchMethod(
        web3Instance,
        "EnvStorageImp",
        "getBallotDurationMinMax",
      ),
    );
    // related member length
    br.add(onlyCallBatchMethod(web3Instance, "GovImp", "getMemberLength"));
    // voting length (여태까지 투표된 숫자)
    br.add(onlyCallBatchMethod(web3Instance, "GovImp", "voteLength"));
    // 1번 블럭 타임 스탬프
    br.add(web3Instance.web3.eth.getBlock.request(1, true));

    try {
      const [
        stakingMin,
        stakingMax,
        duration,
        memberLength,
        voteLength,
        firstBlock,
      ] = await util.asyncExecuteBatch(br);

      data.stakingMin = util.convertWeiToEther(stakingMin);
      data.stakingMax = util.convertWeiToEther(stakingMax);
      data.votingDurationMin = duration[0];
      data.votingDurationMax = duration[1];
      data.memberLength = memberLength;
      data.voteLength = voteLength;
      data.firstBlockTimestamp = firstBlock.timestamp;
    } catch (err) {
      getErrModal(err.message, err.name);
    }
    setIsContractReady(true);
  };

  const getContractAuthorityBallots = async () => {
    await initBallotData();
    util.setUpdatedTimeToLocal(new Date());

    // 퇴출 안건이 올라온 NCP가 실제 퇴출 되었는지 확인
    await getWithdrawalList();
  };

  const getWithdrawalList = async () => {
    const brWithdrawal = new web3Instance.web3.BatchRequest();
    // 퇴출 당한 NCP 목록
    EXITED_NCP_LIST.map((address) =>
      brWithdrawal.add(
        callBatchMethod(
          web3Instance,
          "GovImp",
          "getNodeIdxFromMember",
          address,
        ),
      ),
    );

    const result = await util.asyncExecuteBatch(brWithdrawal);
    // result 값이 0이면 퇴출 당한 것, 아니면 아직 퇴출되지 않은 상태
    EXITED_NCP_LIST.map((address, index) => {
      data.isWithdrawal = {
        ...data.isWithdrawal,
        [address]: {
          exited: result[index] === "0",
        },
      };
      return null;
    });
  };

  // get the ballot list stored in localStorage
  // or initalize new ballot list
  const initBallotData = async () => {
    let ballotBasicFinalizedData = {};
    let ballotMemberFinalizedData = {};
    let localDataUpdated = false;

    const brBallotMember = new web3Instance.web3.BatchRequest();

    const ballotCnt = await onlyCallContractMethod(
      web3Instance,
      "GovImp",
      "ballotLength",
    );
    if (!ballotCnt) return;

    const callCnt = 10; // 한번에 가져올 수량
    // proposal list 가져오기
    const ballotBasic = [];
    for (let i = 1; i <= ballotCnt; i += callCnt) {
      // ballotCnt보다 크면 ballotCnt 값으로 대체
      const lastCnt = i + callCnt - 1 > ballotCnt ? ballotCnt : i + callCnt - 1;
      const list = await callContractMethod(
        web3Instance,
        "GovGatewayImp",
        "getBallotList",
        i,
        lastCnt,
      );
      ballotBasic.push(...list);
    }

    try {
      ballotBasic.map((ret) => {
        const { ballotId } = ret; // id 값 세팅
        const id = Number(ballotId);
        // proposal 데이터 가공
        getBallotBasicOriginDataNew(ret, id, ballotBasicFinalizedData);
        // gov 페이지에 replace authority member newNodeName이 필요해서 얘도 batch 세팅
        if (ret.ballotType === "3") {
          brBallotMember.add(
            callBatchMethod(
              web3Instance,
              "GovGatewayImp",
              "getBallotMember",
              id,
              { ballotId: id },
            ),
          );
          // change env인 경우 title을 뿌리기 위한 batch 세팅
        } else if (ret.ballotType === "5") {
          brBallotMember.add(
            callBatchMethod(
              web3Instance,
              "GovGatewayImp",
              "getBallotVariable",
              id,
              { ballotId: id }, // batch 보낼 때 ballotId를 넣어서 보냄
            ),
          );
        }
        return null;
      });
    } catch (err) {
      console.log("E", err);
      getErrModal(err.message, err.name);
    }
    try {
      // proposal 투표 내용 batch call
      const ballotMember = await util.asyncExecuteBatch(brBallotMember);
      ballotMember.map((result, i) => {
        i++;
        const isUpdatd = ballotBasicFinalizedData[i] !== undefined; // 투표 상태가 approved 이거나 rejected 인 경우
        // proposal 투표 내용 가공
        getBallotMemberOriginDataNew(
          result,
          i,
          isUpdatd,
          ballotMemberFinalizedData,
        );
        return null;
      });
    } catch (err) {
      getErrModal(err.message, err.name);
    }
    if (localDataUpdated) {
      util.setBallotBasicToLocal(ballotBasicFinalizedData);
      util.setBallotMemberToLocal(ballotMemberFinalizedData);
    }
  };

  // proposal 내용 세팅
  const getBallotBasicOriginDataNew = (
    ret,
    i,
    ballotBasicFinalizedData = {},
  ) => {
    // MyInfo 변경 내용은 화면 출력 X (조건: finalized, ballotType: 3, Accepts: 0)
    if (
      ret.isFinalized &&
      ret.ballotType === "3" &&
      ret.powerOfAccepts === "0"
    ) {
      return;
    }
    data.ballotTypeData[i] = ret.ballotType; // for sorting ballot data
    ret = {
      ...ret,
      id: i, // add ballot id
    };

    util.refineBallotBasic(ret);
    data.ballotBasicOriginData[i] = ret;

    // 투표가 끝난 경우 finalized 리스트에 저장
    if (
      ret.state === constants.ballotState.Approved ||
      ret.state === constants.ballotState.Rejected
    ) {
      ballotBasicFinalizedData[i] = ret;
    }
  };

  // proposal 투표 상세 내용 세팅
  const getBallotMemberOriginDataNew = (
    result,
    i,
    isUpdated = false,
    ballotMemberFinalizedData = {},
  ) => {
    const type = data.ballotTypeData[result.ballotId];
    // changeEnv의 경우 envVariableName을 세팅해줌
    if (type === "5") {
      const type = ENV_VOTING_PROPOSAL_LIST.filter((key) => {
        return key.sha3Name === result.envVariableName;
      })[0] || { id: "Change Env" };
      result.envVariableName = type.id;
    }

    // change governance address 의 경우
    if (typeof result !== "object") {
      result = { newGovernanceAddress: result };
    }
    // delete duplicate key values that web3 returns
    for (let key in result) {
      if (!isNaN(key)) delete result[key];
    }

    // result.id = i; // add ballot id
    data.ballotMemberOriginData[i] = result;
    if (isUpdated) ballotMemberFinalizedData[i] = result;
  };

  const getWaitBallots = async () => {
    const brBallotBasic = new web3Instance.web3.BatchRequest();
    const brVote = new web3Instance.web3.BatchRequest();

    const ballotCnt = await onlyCallContractMethod(
      web3Instance,
      "WaitGovernance",
      "ballotLength",
    );

    if (!Number(ballotCnt)) return;
    // proposal 데이터 batch 세팅
    for (let i = 1; i <= ballotCnt; i++) {
      brBallotBasic.add(
        callBatchMethod(
          web3Instance,
          "WaitGovernance",
          "getProposalAndBallot",
          i,
        ),
      );
    }
    // wait vote 세팅
    for (let i = 1; i <= ballotCnt; i++) {
      brVote.add(
        callBatchMethod(web3Instance, "WaitGovernance", "getVotesInBallot", i),
      );
    }
    try {
      // batch call
      const ballotBasic = await util.asyncExecuteBatch(brBallotBasic);
      // 투표 세팅
      const vote = await util.asyncExecuteBatch(brVote);
      ballotBasic.map(({ ballotBasic, proposal }, index) => {
        const abstain = wondersList.map((partner) => parseInt(partner.number));
        const yes = [];
        const no = [];
        const votingList = [];
        // 기존 state에서 Invalid 값이 없어져 수정 (0일 경우 바로 InProgress 상태(+2), 나머지는 +1)
        const state = !parseInt(ballotBasic.state)
          ? "2"
          : `${parseInt(ballotBasic.state) + 1}`;
        // 투표 항목
        const ballotVote = vote[index];
        ballotVote.map((voteItem, key) => {
          votingList.push({
            key,
            voter: voteItem.voter,
            decision: voteItem.decision === "1" ? "Yes" : "No",
          });

          if (voteItem.decision === "1") yes.push(voteItem.voterIdx);
          else if (voteItem.decision === "2") no.push(voteItem.voterIdx);
          // ! 현재 기권 처리 === 찬/반 아무 것도 투표하지 않은 NCP
          // else if (voteItem.decision === "0") abstain.push(voteItem.voterIdx);

          return null;
        });
        // isWait 값 넣어주기
        ballotBasic = {
          ...ballotBasic,
          state,
          isWait: true,
          abstain,
          yes,
          no,
          votingList,
        };
        proposal = {
          ...proposal,
          isWait: true,
        };

        let objBallotBasic = { ...ballotBasic };
        const objProposal = { ...proposal };
        // ballot 형태 맞추기
        objBallotBasic = util.refineBallotBasic(objBallotBasic);

        data.waitBallotBasicOriginData[objBallotBasic.id] = objBallotBasic;
        data.waitBallotMemberOriginData[objBallotBasic.id] = objProposal;

        return null;
      });
      data.waitBallotLength = ballotCnt;
    } catch (err) {
      getErrModal(err.message, err.name);
    }
  };

  /**
   * 특정 시간의 블럭의 node 들의 정보를 가져온다.
   * ballot 의 startTime 으로 timestamp 값 지정
   * @param {number} timestamp
   * @returns node list {id, name, voter, staker, img}
   */
  const getNodeByTimestamp = async (ts, addressNcpInfo) => {
    let parseNodeList;
    // 로컬 버전과 현재 소스 버전 비교
    const localVersion = localStorage.getItem("version");

    if (localVersion !== version) {
      window.localStorage.removeItem("nodeList");
      window.localStorage.setItem("version", version);
    } else {
      if (ts) {
        // localStorage에 저장된 nodeList 값을 가져옴
        const localNodeList = window.localStorage.getItem("nodeList");
        if (localNodeList !== null) {
          try {
            parseNodeList = JSON.parse(localNodeList);
            // 입력 받은 timestamp를 key로 한 노드 정보를 찾음
            const node = Object.entries(parseNodeList).find(
              ([key]) => key === ts,
            );
            if (node !== undefined) {
              // [0] 값은 timestamp
              return node[1];
            }
          } catch {
            // nodeList에 저장된 값이 object의 형태가 아닌 경우
            console.error("노드의 투표 정보를 가져올 수 없습니다.");
            window.localStorage.removeItem("nodeList");
            return [];
          }
        }
      }
    }
    const timestamp = ts ? ts : parseInt(new Date().getTime() / 1000);
    // 투표가 시작되지 않은 경우 및 강제로 진행 상태로 보여주는 경우
    // localStorage에 nodeList 값이 없는 경우
    // nodeList 값은 있지만 입력 받은 timestamp에 맞는 노드 정보가 없는 경우 새로 가져옴
    try {
      // block 번호 예측 (테스트넷&메인넷: 익스플로러 api 호출
      let blockNumber;
      const bnRes = await fetch(
        `${process.env.REACT_APP_EXPLORER_API_URL}/blocks/latest?timestamp=${timestamp}`,
        {
          headers: {
            "api-key":
              "31A9908FC8DBAAB60AD15C3C9B34002EBC4303B0667B1D4E0717CAE227D6D6AD",
          },
        },
      );
      if (!bnRes.ok) {
        throw bnRes;
      }

      const {
        results: {
          data: { block_number },
        },
      } = await bnRes.json();
      blockNumber = block_number;

      const name = await web3Instance.web3.utils.stringToHex(
        "GovernanceContract",
      );
      // ! Registry 에서 Governance contract address 확인. 재배포될면 변경될 수 있음.
      const govAddress = await web3Instance.web3Contracts["Registry"].methods[
        "getContractAddress"
      ](name).call({}, blockNumber);
      // governance contract
      const contract = new web3Instance.web3.eth.Contract(
        abis["GovImp"].abi,
        govAddress,
      );
      // 안건 진행 당시의 memberLength를 가져옴
      const memberLength = await contract.methods
        .getMemberLength()
        .call({}, blockNumber);
      data.proposalMemberLength = memberLength;

      // node 정보와 voter 정보를 reqeust
      const br = new web3Instance.web3.BatchRequest();
      for (let i = 1; i <= memberLength; i++) {
        br.add(contract.methods["getNode"](i).call.request({}, blockNumber));
        br.add(contract.methods["getVoter"](i).call.request({}, blockNumber));
        br.add(contract.methods["getMember"](i).call.request({}, blockNumber));
      }
      const res = await util.asyncExecuteBatch(br);

      // 노드 정보가 없을 경우 처리
      if (res.find((r) => r === null) === null) return [];

      // Node 정보 재구성
      let nodeList = [];
      for (let i = 1; i <= memberLength; i++) {
        const name = res[(i - 1) * 3].name;
        const bpname = Web3.utils
          .hexToString(name || "0x")
          .replace(/[0-9]{2}-/, "");
        const voter = res[(i - 1) * 3 + 1];
        const staker = res[(i - 1) * 3 + 2];
        // id가 정의된 값이 있을 경우 가져옴
        let id = addressNcpInfo[staker]?.id;
        if (!id) {
          // 정의된 값이 없으면 bpname에서 따서 가져옴, 아예 할당되지 않은 노드일 경우 그냥 i 값으로 뿌림
          id = bpname.length
            ? Number(bpname.slice(bpname.length - 2, bpname.length))
            : i;
          // ! 순서 바꿈 -> 40: 1번 WONDER DAO, 37: 40번 WEMADE, 1: 37 미할당)
          id = id === 40 ? 1 : i === 37 ? 40 : i === 1 ? 37 : i;
        }
        nodeList.push({
          id,
          name: addressNcpInfo[staker]?.name
            ? addressNcpInfo[staker]?.name
            : name
            ? bpname
            : "",
          voter,
          staker,
          img: addressNcpInfo[staker]?.img || "/img/ncp/default.png",
        });
      }
      nodeList.sort((a, b) => a.id - b.id);

      // localStorage에 node 정보 저장
      if (ts) {
        parseNodeList = {
          ...parseNodeList,
          [timestamp]: nodeList,
        };
        window.localStorage.setItem("nodeList", JSON.stringify(parseNodeList));
      }

      return nodeList;
    } catch (e) {
      console.error(e);
      return [];
    }
  };

  const getPlatform = async () => {
    try {
      // localStorage에 저장되어 있으면 api 때리지 않음
      // let platformList =
      //   JSON.parse(window.localStorage.getItem("platformList")) || null;
      // if (platformList === null) {
      const platformList = await getPlatformList();
      window.localStorage.setItem("platformList", JSON.stringify(platformList));
      // }
      // return platformList;
    } catch (e) {
      console.error(e);
      window.localStorage.setItem("platformList", "[]");
      // return [];
    }
  };

  // devnet에서만 사용 (api 호출이 불가능한 이슈 때문에)
  const getRewardHistoryFromEvents = async (contract) => {
    try {
      // 현재 시간
      const timestamp = parseInt(new Date().getTime() / 1000);
      const blockNumber = parseInt((timestamp - data.firstBlockTimestamp) / 3); // 현재 예상되는 블록번호
      const fromBlock = blockNumber - 86400 / 3; // 예상한 블록 넘버 기준 1일 전 블록 번호 (devnet 블록 생성 시간 3초)

      const events = await contract.getPastEvents("LogOnReward", {
        fromBlock: fromBlock,
        toBlock: "latest",
      });

      // api로 넘어오는 형식과 동일하게 맞춤
      let returnEvents = [];
      events.map((item) => {
        const { transactionHash, returnValues, blockNumber } = item;
        const { amount, fee, platformFee } = returnValues;

        returnEvents.push({
          hash: transactionHash,
          data: {
            user_claim_amount: BigNumber(amount)
              .minus(fee)
              .minus(platformFee)
              .toFixed(),
          },
          timestamp: new Date(
            (blockNumber * 3 + data.firstBlockTimestamp) * 1000,
          ),
        });

        return null;
      });
      return returnEvents.reverse();
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <GovInitCtx.Provider
      value={{
        isWeb3Loaded,
        isContractReady,
        data,
        accessFailMsg,
        getNodeByTimestamp,
        getRewardHistoryFromEvents,
      }}
    >
      {children}
    </GovInitCtx.Provider>
  );
};

export { GovInitProvider, GovInitCtx };
